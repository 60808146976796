<template>
  <div>
    <div class="accountHead">
      <img src="@/assets/accountHeadPic.png" alt style="width: 75px" />
      <div class="accountInfo">{{ ownAccount }}</div>
    </div>
    <el-form ref="form" :model="form" :rules="rules" label-width="80px">
      <el-form-item label="原密码" prop="orgPassword">
        <el-input v-model="form.orgPassword" show-password></el-input>
      </el-form-item>
      <el-form-item label="新密码" prop="newPassword">
        <el-input v-model="form.newPassword" show-password></el-input>
      </el-form-item>
      <div class="passwordTips">密码由6-15位的阿拉伯数字或英文字母组成</div>
      <el-form-item label="确认密码" prop="repNewPassword">
        <el-input v-model="form.repNewPassword" show-password></el-input>
      </el-form-item>
      <el-form-item>
        <el-button v-throttle type="primary" @click="changeClick('form')"
          >保存修改</el-button
        >
      </el-form-item>
    </el-form>
    <div class="accountTail">
      <img src="@/assets/accountPic.png" alt style="width: 650px" />
    </div>
  </div>
</template>
<script>
import { getUserDetails, revisePassword } from '@/api/account/accountApi.js'
export default {
  data() {
    //校验规则
    var checkorgPassword = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入原密码'))
      } else {
        if (this.rulesNum == 1) {
          return callback(new Error('原密码错误'))
        } else {
          callback()
        }
      }
    }
    var checkNewPassword = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请按规则输入密码'))
      } else {
        if (value.length < 6 || value.length > 15) {
          callback(new Error('请按规则输入密码'))
        } else {
          if (this.regularNewPassword(value)) {
            callback(new Error('请按规则输入密码'))
          } else {
            callback()
          }
        }
      }
    }
    var checkRepNewPassword = (rule, value, callback) => {
      if (value != this.form.newPassword) {
        callback(new Error('密码不一致'))
      } else {
        callback()
      }
    }
    return {
      form: {
        orgPassword: '',
        newPassword: '',
        repNewPassword: '',
      },
      ownAccount: '',
      //校验变量
      rulesNum: 0,
      rules: {
        orgPassword: [{ validator: checkorgPassword, trigger: 'blur' }],
        newPassword: [{ validator: checkNewPassword, trigger: 'blur' }],
        repNewPassword: [{ validator: checkRepNewPassword, trigger: 'blur' }],
      },
    }
  },

  mounted() {
    this.getUserInfo()
  },

  methods: {
    //获取用户账号
    getUserInfo() {
      getUserDetails().then((res) => {
        let { code, data } = res
        this.ownAccount = data.account
      })
    },

    //修改密码
    changeClick(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let passwordParam = {
            account: this.ownAccount,
            newPassword: this.form.newPassword,
            password: this.form.orgPassword,
          }
          revisePassword(passwordParam).then((res) => {
            console.log(res, 'ressss')
            let { success } = res
            if (!success) {
              this.rulesNum = 1
              this.$refs.form.validate()
              this.rulesNum = 0
            } else {
              this.$message({
                message: '修改密码成功',
                type: 'success',
              })
              setTimeout(() => {
                this.$router.back()
              }, 700)
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },

    //新密码正则
    regularNewPassword(val) {
      return /[^a-zA-Z0-9]/g.test(val)
    },
  },
}
</script>

<style lang="scss" scoped>
.el-button {
  width: 300px;
  border-radius: 22px 22px 22px 22px;
  margin-left: 50px;
}
.el-form {
  width: 500px;
  margin-left: 33%;
  margin-top: 40px;
}
.accountHead {
  display: block;
  text-align: center;
  margin-top: 100px;
  .accountInfo {
    font-size: 16px;
    font-weight: bold;
  }
}
.passwordTips {
  font-size: 12px;
  color: #2e9aff;
  margin-left: 266px;
  position: relative;
  margin-top: -18px;
  margin-bottom: 5px;
}
.accountTail {
  text-align: center;
}
</style>
