import {
  request,
  noTimeOutReq
} from '@/utils/request.js'

//获取登录用户详情
export function getUserDetails() {
  return request({
    url: `/user/admin/user/login/get`,
    method: 'post',
  })
}

//修改登陆用户信息
export function reviseUserDetails(data) {
  return request({
    url: `/admin/user/update/login/user`,
    method: 'post',
    data
  })
}

//修改登录用户密码
export function revisePassword(data) {
  return request({
    url: `/admin/user/update/password/login/user`,
    method: 'post',
    data
  })
}